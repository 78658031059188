export const aduan32 = [
    //FINAL
    {
        id: 31,
        name: 'Final - Match',
        nextLooserMatchId: null,
        nextMatchId: null,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '5'
    },
    //SEMI FINAL
    {
        id: 29,
        name: 'Semi Final - Match 1',
        nextLooserMatchId: null,
        nextMatchId: 31,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '4'
    },
    {
        id: 30,
        name: 'Semi Final - Match 2',
        nextLooserMatchId: null,
        nextMatchId: 31,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '4'
    },
    //ROUND 3
    {
        id: 25,
        name: 'Round 3 - Match 1',
        nextLooserMatchId: null,
        nextMatchId: 29,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '3'
    },
    {
        id: 26,
        name: 'Round 3 - Match 2',
        nextLooserMatchId: null,
        nextMatchId: 29,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '3'
    },
    {
        id: 27,
        name: 'Round 3 - Match 3',
        nextLooserMatchId: null,
        nextMatchId: 30,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '3'
    },
    {
        id: 28,
        name: 'Round 3 - Match 4',
        nextLooserMatchId: null,
        nextMatchId: 30,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '3'
    },
    //ROUND 2
    {
        id: 17,
        name: 'Round 2 - Match 1',
        nextLooserMatchId: null,
        nextMatchId: 25,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 18,
        name: 'Round 2 - Match 2',
        nextLooserMatchId: null,
        nextMatchId: 25,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 19,
        name: 'Round 2 - Match 3',
        nextLooserMatchId: null,
        nextMatchId: 26,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 20,
        name: 'Round 2 - Match 4',
        nextLooserMatchId: null,
        nextMatchId: 26,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 21,
        name: 'Round 2 - Match 5',
        nextLooserMatchId: null,
        nextMatchId: 27,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 22,
        name: 'Round 2 - Match 6',
        nextLooserMatchId: null,
        nextMatchId: 27,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 23,
        name: 'Round 2 - Match 7',
        nextLooserMatchId: null,
        nextMatchId: 28,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    {
        id: 24,
        name: 'Round 2 - Match 8',
        nextLooserMatchId: null,
        nextMatchId: 28,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '2'
    },
    //ROUND 1
    {
        id: 1,
        name: 'Round 1 - Match 1',
        nextLooserMatchId: null,
        nextMatchId: 17,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 2,
        name: 'Round 1 - Match 2',
        nextLooserMatchId: null,
        nextMatchId: 17,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 3,
        name: 'Round 1 - Match 3',
        nextLooserMatchId: null,
        nextMatchId: 18,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 4,
        name: 'Round 1 - Match 4',
        nextLooserMatchId: null,
        nextMatchId: 18,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 5,
        name: 'Round 1 - Match 5',
        nextLooserMatchId: null,
        nextMatchId: 19,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 6,
        name: 'Round 1 - Match 6',
        nextLooserMatchId: null,
        nextMatchId: 19,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 7,
        name: 'Round 1 - Match 7',
        nextLooserMatchId: null,
        nextMatchId: 20,
        participants: [
        ],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 8,
        name: 'Round 1 - Match 8',
        nextLooserMatchId: null,
        nextMatchId: 20,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 9,
        name: 'Round 1 - Match 9',
        nextLooserMatchId: null,
        nextMatchId: 21,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 10,
        name: 'Round 1 - Match 10',
        nextLooserMatchId: null,
        nextMatchId: 21,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },

    {
        id: 11,
        name: 'Round 1 - Match 11',
        nextLooserMatchId: null,
        nextMatchId: 22,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 12,
        name: 'Round 1 - Match 12',
        nextLooserMatchId: null,
        nextMatchId: 22,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },

    {
        id: 13,
        name: 'Round 1 - Match 13',
        nextLooserMatchId: null,
        nextMatchId: 23,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 14,
        name: 'Round 1 - Match 14',
        nextLooserMatchId: null,
        nextMatchId: 23,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },

    {
        id: 15,
        name: 'Round 1 - Match 15',
        nextLooserMatchId: null,
        nextMatchId: 24,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
    {
        id: 16,
        name: 'Round 1 - Match 16',
        nextLooserMatchId: null,
        nextMatchId: 24,
        participants: [],
        startTime: null,
        state: 'SCORE_DONE',
        tournamentRoundText: '1'
    },
]